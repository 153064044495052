import { css } from 'styled-components'

export const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const insHeaderStyles = css`
  ins {
    text-underline-offset: 10px;
    text-decoration-color: ${p => p.theme.colors.primary};
  }
`

export const titlesUnderline = css`
  text-decoration: underline ${p => p.theme.colors.primary} 4px;
  text-underline-offset: 8px;
`
