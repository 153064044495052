import dynamic from 'next/dynamic'
import type { ComponentType, FC } from 'react'
import { memo } from 'react'

import type { NextApplicationsNamesType } from '@Types/applications/nextApps'
import { ApplicationNames } from '@constants/applications/applicationNames'
import getAppConfig from '@services/config/config'

const { publicRuntimeConfig } = getAppConfig()

type Props = {
  isShort?: boolean
}

const siteLogos: Record<NextApplicationsNamesType, ComponentType<Props>> = {
  [ApplicationNames.HST]: dynamic(() => import('@hstComponents/SiteLogo')),
  [ApplicationNames.RN_CLASSIC]: dynamic(() => import('@rnComponents/SiteLogo')),
  [ApplicationNames.RN_INTERIM]: dynamic(() => import('@rnComponents/SiteLogo')),
  [ApplicationNames.RN_MODERN]: dynamic(() => import('@rnComponents/SiteLogo')),
  [ApplicationNames.RN_NEW]: dynamic(() => import('@rnComponents/SiteLogo')),
}

const SiteLogo: FC<Props> = ({ isShort }) => {
  const Logo = siteLogos[publicRuntimeConfig.site as NextApplicationsNamesType]

  return <Logo isShort={isShort} />
}

export default memo(SiteLogo)
